h1 {
  font-size: 24px;
}

.base-container-border {
  padding: 30px;
}

.medium_size_text {
  font-size: 14px;
}

.basic-checkbox label.checkbox {
  font-size: 14px;
}

.basic-checkbox {
  align-items: center;
  margin-bottom: 15px;
}

#parent-email-checkbox {
  margin: 0 10px 0 0;
}

.third_text_acc_creation {
  font-size: 12px;
}

.faded-text {
  color: #9a9a9a;
}

.centered-text {
  text-align: center;
  display: grid;
}

.input-error {
  font-size: 12px;
  color: #f00;
}

.modal-contents {
  font-family: Flexo-Regular, serif;
}

.grey-callout-box {
  border-radius: 10px;
  background: var(--lightest-grey);
  padding: 20px;
}

.email-callout {
  font-size: 14px;
  margin-bottom: 30px;
}

.error-email-validation {
  font-size: 12px;
  color: #f00;
  text-align: left;
  margin-bottom: 15px;
}

.error-email-validation-support {
  text-decoration: none;
  color: #31a7d9;
}

.success-page-main {
  margin-bottom: 30px;
  text-align: center;
  display: grid;
}

.change-display-form {
  display: grid;
}