body {
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    'Courier New',
    monospace;
}

@font-face {
  font-family: Flexo;
  src: url("/src/fonts/Flexo-Demi.woff2") format("woff2"),
      url("/src/fonts/Flexo-Demi.woff") format("woff");
      font-weight: normal;
      font-style: normal;
      font-display: swap;
  }
  
  @font-face {
  font-family: Flexo;
  src: url("/src/fonts/Flexo-Regular.woff2") format("woff2"),
      url("/src/fonts/Flexo-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  }
  
  @font-face {
  font-family: Flexo;
  src: url("/src/fonts/Flexo-Heavy.woff2") format("woff2"),
      url("/src/fonts/Flexo-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  }
  
  @font-face {
  font-family: Flexo;
  src: url("/src/fonts/Flexo-Light.woff2") format("woff2"),
      url("/src/fonts/Flexo-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  }
  
  @font-face {
  font-family: Flexo;
  src: url("/src/fonts/Flexo-Medium.woff2") format("woff2"),
      url("/src/fonts/Flexo-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  }
  
  