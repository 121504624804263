.title {
  font-size: 24px;
  margin: 0;
}

.division-line {
  height: 1px;
  background-color: #ddd;
  border: none;
}

.privacy-formatting {
  display: grid;
  grid-gap: 10px;
}