.scopes-display {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  max-height: 300px;
  overflow-y: scroll;
}

.callout-box > p {
  margin: unset;
}
