.public-profile-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.public-profile-title {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.public-profile-toggle {
  flex: 0 0 auto;
}

.profileHeader {
  line-break: strict;
  overflow: hidden;
  text-overflow: ellipsis;
  word-spacing: 100vw;
  max-height: 3em;
}

.change-password-form {
  display: grid;
}