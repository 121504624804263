.user-info {
  display: flex;
  flex-direction: row;
}

.letter-icon {
  width: 35px;
  height: 35px;
  padding-right: 15px;
}

.email-info {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: var(--light-grey);
}

.input-label {
  font-size: 12px;
  font-weight: bold;
}

.verification_code_message_section {
  margin-bottom: 20px;
}

.resend-code-section {
  font-size: 12px;
  text-align: end;
}

.resend-code {
  font-family: Flexo-Regular, serif;
  appearance: none;
  border: none;
  color: #31a7d9;
  background: none;
  cursor: pointer;
}

.toast-text {
  font-family: Flexo-Regular, serif;
}

.circle-check-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.toast-message {
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.error-invalid {
  font-size: 12px;
  color: #f00;
  text-align: left;
  margin-bottom: 15px;
}

.change-email-form {
  display: grid;
}