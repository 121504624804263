.main-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error-message {
  font-size: 20px;
  color: #000;
}

.error-image {
  height: 198px;
  width: 170px;
  padding: 20px 0;
}
