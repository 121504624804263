.pokemon-go-child-permissions h1 {
  margin: 0;
}

.pokemon-go-child-permissions {
  margin-bottom: 80px;
}

.pokemon-go-child-permissions .permission-block-description {
  font-size: 14px;
  margin-right: 0;
  padding-left: 10px;
  margin-top: 10px;
}

.pokemon-go-permissions .spinner {
  margin-top: 0;
}

.pokemon-go-terms p {
  margin: 0 0 12px;
  font-size: 14px;
}

.pokemon-go-terms p:last-child {
  margin-bottom: 0;
}
